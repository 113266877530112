import {HashRouter, Route, Routes} from "react-router-dom";
import {NotFoundPage, StartPage} from "./pages";
import {AppLayout} from "../layouts";
import {Language} from "../models/Language";

export const MainRoutes = () => {
    return (
        <HashRouter>
            <Routes>
                <Route path="" element={<AppLayout language={Language.no}/>}>
                    <Route path="*" element={<NotFoundPage language={Language.no}/>} />
                    <Route index element={<StartPage language={Language.no}/>} />
                </Route>
                <Route path="en" element={<AppLayout language={Language.en}/>}>
                    <Route path="*" element={<NotFoundPage language={Language.en}/>} />
                    <Route index element={<StartPage language={Language.en}/>} />
                </Route>
            </Routes>
        </HashRouter>
    );
}; 