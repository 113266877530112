import {useEffect, useState} from "react";
import {getDataFromAPI} from "../services/APIService";
import {getDataFromFile} from "../services/FileService";
import {parseJson} from "../helpers/jsonHelper";
import {SearchAreaViewModel} from "../models/SearchAreaViewModel";
import {Country} from "../models/Country";
import {Doctor} from "../models/Doctor";
import {Language} from "../models/Language";
import SearchForm from "./SearchForm";
import SearchResults from "./SearchResults";

interface ISearchArea {
    language: Language;
}

export default function SearchArea({language}: ISearchArea) {
    const [countries, setCountries] = useState<Country[]>([]);
    const [doctors, setDoctors] = useState<Doctor[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);

    const [selectedCountry, setSelectedCountry] = useState<string>(language === Language.no ? 'Norge' : 'Norway');
    const [selectedCity, setSelectedCity] = useState<string>('');

    useEffect(() => {
        setIsLoading(true);
        
        let parsedData: SearchAreaViewModel;
        if (process.env.NODE_ENV === 'development') {
            parsedData = parseJson(getDataFromFile(language));
            setDoctors(parsedData.doctors);
            setCountries(parsedData.countries);
            setIsLoading(false);
        } else {
            getDataFromAPI(language)
                .then((result) => {
                    parsedData = parseJson(result.data);
                    setDoctors(parsedData.doctors);
                    setCountries(parsedData.countries);
                })
                .catch(err => {
                    console.error(err);
                    setIsError(true);
                }).finally(() => 
                    setIsLoading(false));
        }
    }, [language]);

    function handleCountryChange(country: string) {
        setSelectedCountry(country);
        const countryData = countries.find((c) => c.name === country);
        if (countryData && countryData.cities.length === 1) {
            setSelectedCity(countryData.cities[0].name);
        } else {
            setSelectedCity('');
        }
    }

    function handleCityChange(city: string) {
        setSelectedCity(city);
    }

    return (
        <div>
            <SearchForm language={language} countries={countries} selectedCountry={selectedCountry} selectedCity={selectedCity} onCountryChange={handleCountryChange} onCityChange={handleCityChange}/>
            <SearchResults language={language} isLoading={isLoading} isError={isError} doctors={doctors} selectedCountry={selectedCountry} selectedCity={selectedCity} />
        </div>
    )
}