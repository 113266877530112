import {Select, styles} from "@sdir/sds";
import styled from "styled-components";
import {Country} from "../models/Country";
import {City} from "../models/City";
import {Language} from "../models/Language";
import lang from "../lang/language.json";

interface ISearchForm {
    language: Language;
    countries: Country[];
    selectedCountry: string;
    selectedCity: string;
    onCountryChange: ( country: string ) => void,
    onCityChange: ( city: string ) => void
}

export default function SearchForm({language, countries, selectedCountry, selectedCity, onCountryChange, onCityChange}: ISearchForm) {

    let cities: City[] = [];

    const countryData = countries.find((country) => country.name === selectedCountry);
    if(countryData) {
        cities = countryData.cities;
    }

    return (
        <StyledSearchForm role="search">
            <SearchField>
                <SearchLabel>{lang[language].searchForm.countryLabel}</SearchLabel>
                <Select
                    defaultVal={selectedCountry}
                    options={countries}
                    listLabel={lang[language].searchForm.selectCountryLabel}
                    handleChange={(_: any, option: any) => {
                        onCountryChange(option.name);
                    }}
                    grouped
                    searchable
                    searchLabel={lang[language].searchForm.searchForCountryLabel}
                />
            </SearchField>
            <SearchField>
                <SearchLabel>{lang[language].searchForm.cityLabel}</SearchLabel>
                <Select
                    defaultVal={selectedCity || lang[language].searchForm.selectCityLabel}
                    options={cities}
                    listLabel={lang[language].searchForm.selectCityLabel}
                    handleChange={(_: any, option: any) => {
                        onCityChange(option.name);
                    }}
                    disabled={cities.length < 1}
                />
            </SearchField>
        </StyledSearchForm>
    );
}

const StyledSearchForm = styled.form`
    display: flex;
    gap: 2rem;
    margin-top: 3rem;
    padding-top: 2.5rem; 
    border-top: 1px solid ${styles.colors.border};
    @media (max-width: 700px) {
        flex-direction: column;
    }
`

const SearchField = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: .5rem;
    width: 100%;
    
    @media (min-width: 1024px) {
        width: calc(33.3333% - 2rem);
    }
`

const SearchLabel = styled.span`
    font-size: 2rem;
    font-family: ${styles.fonts.stfSemibold};
`