import styled from "styled-components";
import {styles} from "@sdir/sds";
import {Doctor} from "../models/Doctor"
import {Language} from "../models/Language";
import lang from "../lang/language.json";

interface ISearchItem {
    language: Language
    doctor: Doctor;
}

export default function SearchItem({language, doctor}: ISearchItem) {
    return (
        <StyledSearchItem>
            <article>
                <h2>
                    {doctor.name}
                </h2>
                <dl>
                    {(doctor.zipCode || doctor.city) && (
                        <div>
                            <dt>{lang[language].searchItem.location}</dt>
                            <dd>{doctor.city}, {doctor.zipCode}</dd>
                        </div>
                    )}
                    {doctor.street && (
                        <div>
                            <dt>{lang[language].searchItem.address}</dt>
                            <dd>{doctor.street}</dd>
                        </div>
                    )}
                    {doctor.phone && (
                        <div>
                            <dt>{lang[language].searchItem.phoneNumber}</dt>
                            <dd><a href={`tel:${doctor.phone}`}>{doctor.phone}</a></dd>
                        </div>
                    )}
                    {doctor.homepage && (
                        <div>
                            <dt>{lang[language].searchItem.website}</dt>
                            <dd><a href={doctor.homepage} title={doctor.homepage} target="_blank"
                                   rel="noreferrer">{lang[language].searchItem.goToWebsite}</a></dd>
                        </div>
                    )}
                </dl>
            </article>
        </StyledSearchItem>
    )
}

const StyledSearchItem = styled.li`

    article {
        background-color: ${styles.colors.background.white};
        border-radius: ${styles.card.borderRadius};
        border: 1px solid ${styles.colors.border};
        position: relative;
        transition: border-color .15s ease;
    }

    h2 {
        margin: 0;
        font-size: 2.5rem;
        font-family: ${styles.fonts.stfMedium};
        padding: 1rem 2rem;
        @container grid-column (min-width: 1000px) {
            padding: 1.5rem 2.5rem;
        }
    }
    
    dl {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        border-top: 1px solid ${styles.colors.border};
        padding: 1rem 2rem;
        gap: 1rem;
        @container grid-column (min-width: 1000px) {
            padding: 1.5rem 2.5rem;
            gap: 1.5rem;
            grid-template-columns: repeat(4, 1fr);
        }
        
        div {
            display: flex;
            flex-direction: column;
            gap: .25rem;
            line-height: 1.25;
            padding-bottom: 1rem;
            padding-top: 1rem;
            
            @container grid-column (min-width: 1000px) {
                border-right: 1px solid ${styles.colors.border};
                padding-right: 1rem;
                &:last-child {
                    border-right: 0;
                }
            }
            
            @container grid-column (max-width: 1000px) {
                border-bottom: 1px solid ${styles.colors.border};
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
    
    dt {
        font-family: ${styles.fonts.stfMedium};
        font-size: 1.75rem;
        color: ${styles.colors.primary};
    }
    
    dd {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        text-wrap: balance;
        margin-top: .25em;
        
        a {
            color: ${styles.colors.primary};
            text-decoration: underline;
            text-underline-offset: 2px;
            &[href*="tel:"] {
                letter-spacing: .05em;
            }
        }
    }
    `