import {Language} from "../../models/Language";
import lang from "../../lang/language.json";
import styled from "styled-components";
import {styles} from "@sdir/sds";

interface INotFoundPage {
    language: Language
}

const NotFoundPageComponent = ({ language }: INotFoundPage) => {

    return (
        <main>
            <div className="l-container">
                <PageTitle>{lang[language].notFoundPage.pageTitle}</PageTitle>
            </div>
        </main>
    )
}

export const NotFoundPage = NotFoundPageComponent;

const PageTitle = styled.h1`
    font-family: ${styles.fonts.stfSemibold};
    font-size: 4rem;
    line-height: 1.25;
    margin: 0;
    margin-top: 8rem;
    @media (max-width: 700px) {
        font-size: 3rem;
    }
`