import SearchItem from "./SearchItem";
import styled from "styled-components";
import {styles} from "@sdir/sds";
import {Doctor} from "../models/Doctor";
import {Language} from "../models/Language";
import lang from "../lang/language.json";

interface ISearchResults {
    language: Language;
    isLoading: boolean;
    isError: boolean;
    doctors: Doctor[];
    selectedCountry: string;
    selectedCity: string;
}

export default function SearchResults({language, isLoading, isError, doctors, selectedCountry, selectedCity}: ISearchResults) {

    let filteredDoctors: Doctor[] = [];
    if (selectedCity) {
        filteredDoctors = doctors.filter((doctor) => doctor.city?.toLowerCase().trim() === selectedCity.toLowerCase());
    } else {
        filteredDoctors = doctors.filter((doctor) => doctor.country?.toLowerCase().trim() === selectedCountry.toLowerCase())
    }

    return (
        <StyledSearchResults>
            {isError && (
                <SearchResultsInfo><strong>{lang[language].searchResults.errorMessage}</strong></SearchResultsInfo>
            )}
            {!isLoading ? (
                <>
                    <SearchResultsInfo aria-live="assertive"><strong>{filteredDoctors.length}</strong> {lang[language].searchResults.numberOfHitsText}</SearchResultsInfo>
                    <SearchResultsList>
                        {filteredDoctors.map((doctor) =>
                            <SearchItem key={doctor.id} doctor={doctor} language={language}/>
                        )}
                    </SearchResultsList>
                </>
            ) : (
                <>
                    <GhostLoader style={{ minHeight: "40px" }} />
                    <GhostLoader style={{ minHeight: "100px" }} />
                </>
            )}
        </StyledSearchResults>
    )
}

const StyledSearchResults = styled.div`
    container-type: inline-size;
    container-name: grid-column;
    margin-top: 4rem;
    padding-bottom: 8rem;
`

const SearchResultsInfo = styled.p`
    font-size: 2rem;
`

const SearchResultsList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`

const GhostLoader = styled.div`
    background-color: #efefef;
    border-radius: ${styles.card.borderRadius};
    margin-bottom: 2rem;
    background-image:			
        linear-gradient(
            90deg, 
            rgba(255,255,255, 0), 
            rgba(255,255,255, 0.5),
            rgba(255,255,255, 0)
        );
    background-size: 25% 100%; 
	background-repeat: no-repeat; 
	background-position: left -50% top 0;
	animation: shine 1.25s ease infinite;
    
    @keyframes shine {
        to {
            background-position: right -50% top 0;
        }
    }
`