import {Language} from "../models/Language";
import { httpClient } from "httpClient";

export type Result = {
    data?: any
}

export const getDataFromAPI = async function (language : Language) : Promise<Result> {
    let result: Result = {};

    try {
        const url = 'v1/seafarers-doctors?language=' + language;
        const response = await httpClient.client.get(url);
        result.data = response.data;
    } catch (err) {
        throw err;
    }
    
    return result;
}
