import {SearchAreaViewModel} from "../models/SearchAreaViewModel";
import {Doctor} from "../models/Doctor";
import {Country} from "../models/Country";
import {City} from "../models/City";

export function parseJson(json: any) : SearchAreaViewModel {
    const model: SearchAreaViewModel = { countries: [], doctors: [] }

    for (const region of Object.keys(json)) {

        const countriesJson = json[region];
        for (const countryName of Object.keys(countriesJson)) {
            const country: Country = {
                name: countryName,
                value: countryName.toLowerCase(),
                group: region,
                cities: []
            }

            const citiesJson = countriesJson[countryName];
            for (const cityName of Object.keys(citiesJson)) {
                const city: City = {
                    name: cityName,
                    value: cityName.toLowerCase()
                }
                
                country.cities.push(city);

                const doctors = citiesJson[cityName];
                for (const doctorIndex of Object.keys(doctors)) {
                    const doctor: Doctor = doctors[doctorIndex];
                    doctor.id = doctor.name.toLowerCase().replaceAll(' ', '') + '-' + crypto.randomUUID();
                    doctor.country = countryName; //Needed to set country name in correct language
                    model.doctors.push(doctor);
                }
            }

            model.countries.push(country);
        }
    }

    return model;
}