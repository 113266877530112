import {faGlobeAmericas} from "@fortawesome/free-solid-svg-icons";
import {Outlet, useNavigate} from "react-router";
import {Icon, SdirFooter, SdirHeader} from "@sdir/sds";
import lang from "../lang/language.json";
import {Language} from "../models/Language";
import styled from "styled-components";

interface IAppLayout {
    language: Language
}

const AppLayoutComponent = ({language}: IAppLayout) => {
    document.documentElement.lang = language;

    const navigate = useNavigate();

    return (
        <AppLayoutWrapper>
            <SdirHeader
                backLink={{ url: "https://www.sdir.no" + (language === Language.no ? "" : "/en"), text: lang[language].layout.headerBackLinkText }}
                appName={lang[language].layout.appTitle}
                maxContentWidth="1200px"
                menuToggleText="Meny"
                toolMenuItems={[
                    {
                        type: 'button',
                        text: lang[language].layout.translationButtonLabel,
                        icon: <Icon name={faGlobeAmericas as any} padding="0" />,
                        onClick: () => {
                            navigate(language === Language.no ? '/en' : '/', {replace: true});
                            navigate(0);
                        }
                    }
                ]}
            />
            <Wrapper>
                <Outlet />
            </Wrapper>
            <SdirFooter 
                contactHeader={lang[language].layout.contact}
                contactLinks={[
                    { url: 'https://www.sdir.no/om-direktoratet/kontakt-oss/kontakt/', text: lang[language].layout.contact, type: 'external' },
                    { url: 'https://www.sdir.no/om-direktoratet/presentasjon-av-direktoratet/', text: lang[language].layout.about, type: 'external' }
                ]}
                socialHeader={lang[language].layout.follow}
                socialLinks={[
                    { url: 'https://www.facebook.com/sjofartsdir/', text: 'Facebook', type: 'facebook' },
                    { url: 'https://www.linkedin.com/company/sjofartsdir', text: 'Linkedin', type: 'linkedin' }
                ]}
                tagline={lang[language].layout.tagline}
                maxContentWidth="1200px"
            />
        </AppLayoutWrapper>
    );
};

const AppLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const Wrapper = styled.div`
    flex-grow: 1;
`;

export const AppLayout = AppLayoutComponent;
